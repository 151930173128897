import React from 'react';
import hexToRgba from 'hex-to-rgba';
import { SYSTEM_FONTS } from '../../styles/typography';

interface Props {
  image: string;
  title: string;
  text: string;
}

export default function Perk({ image, title, text }: Props) {
  return (
    <div css={{ marginBottom: '3rem' }}>
      <div css={{ height: 75, marginBottom: 24 }}>
        <img src={image} alt={title} css={{ maxWidth: 78 }} />
      </div>
      <h5
        css={{
          fontFamily: ['Recoleta Alt'].concat(SYSTEM_FONTS).join(', '),
          fontWeight: 700,
          fontSize: 20,
          color: '#160B2C',
        }}
      >
        {title}
      </h5>
      <p
        css={{
          fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
          fontWeight: 400,
          fontSize: '0.875rem',
          color: hexToRgba('#160B2C', 0.8),
          b: {
            fontWeight: 400,
          },
        }}
      >
        {text}
      </p>
    </div>
  );
}
